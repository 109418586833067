/* General Body Font */
body {
  font-family: 'Poppins', sans-serif;
}

/* Carousel Section */
.home-carousel {
  position: relative;
  overflow: hidden;
}

.home-carousel .carousel-item img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  animation: zoomIn 10s ease-in-out infinite;
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.home-carousel .carousel-caption-left {
  top: 60%;
  left: 5%;
  right: auto;
  transform: translateY(-50%);
  text-align: left;
  padding: 2rem;
  border-radius: 10px;
  animation: fadeIn 1.5s ease-in-out;
}

.carousel-title {
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 1rem;
  animation: slideInLeft 1s ease-out;
}

.carousel-text {
  font-size: 1.5rem;
  font-weight: 400;
  color: #ddd;
  max-width: 80%;
  animation: slideInLeft 1.5s ease-out;
}

/* Remove Carousel Controls */
.carousel-control-prev,
.carousel-control-next {
  display: none;
}

@keyframes slideInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/* Services Section */
.w3l-services {
  background-color: #f9f9f9;
  padding: 2rem 0;
}

.section-title {
  font-size: 2.75rem;
  font-weight: 800;
  margin-bottom: 0.75rem;
  color: #333;
  text-transform: uppercase;
}

.section-subtitle {
  font-size: 1.5rem;
  color: #6c757d;
  margin-bottom: 2rem;
}

/* Service Cards */
.service-card {
  background: linear-gradient(135deg, #1e3c72, #2a5298, #6dd5fa, #2980b9, #1e3c72);
  background-size: 300% 300%;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transform: translateY(30px);
  animation: gradientAnimation 10s ease infinite, fadeInUp 0.6s ease forwards var(--animation-delay);
}

.service-card.animate {
  opacity: 1;
  transform: translateY(0);
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.service-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.service-icon {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.icon-broadband {
  color: #ff5722;
}

.icon-leased-line {
  color: #4caf50;
}

.icon-ott {
  color: #2196f3;
}

.service-title {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: bold;
}

.Our {
  font-size: 4rem;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: bold;
}
.service-description {
  font-size: 1rem;
  color: #000000;
  margin-top: auto;
}

/* Responsive design for services cards */
@media (max-width: 767px) {
  .service-card {
    padding: 1rem;
  }

  .service-icon {
    font-size: 3rem;
  }

  .service-title {
    font-size: 1.5rem;
  }
  .Our{
    font-size: 3rem;
  }
  .sec{
    font-size: 0.8rem;
  }
  .service-description {
    font-size: 0.875rem;
  }
  .home-carousel .carousel-item img {
    width: 100%;
    height: 35vh;
    object-fit: cover;
    animation: zoomIn 10s ease-in-out infinite;
  }
  .home-carousel .carousel-caption-left {
    top: 60%;
    left: 5%;
    right: auto;
    transform: translateY(-50%);
    text-align: left;
    padding: 2rem;
    border-radius: 10px;
    animation: fadeIn 1.5s ease-in-out;
  }
  
  .carousel-title {
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 1rem;
    animation: slideInLeft 1s ease-out;
    margin-left: -30px;
  }
  
  .carousel-text {
    font-size: 0.7rem;
    font-weight: 400;
    color: #ddd;
    max-width: 80%;
    animation: slideInLeft 1.5s ease-out;
    line-height: 15px;
    margin-left: -30px;
  }
  
  /* Remove Carousel Controls */
  .carousel-control-prev,
  .carousel-control-next {
    display: none;
  }
  .section-title{
    font-size: 2rem;
  }
  .section-subtitle{
    font-size: 0.8rem;
  }
  .about-image img {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
    width: 100%;
    height: 20vh !important;
    object-fit: cover;
  }
 
}

/* About SCUD Communication section */
.w3l-about {
  background-color: #f1f1f1;
  padding: 2rem 0;
}

.about-image img {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
  width: 100%;
  height: auto;
}

.about-image img:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.about-description {
  font-size: 1rem;
  color: #333;
  margin-top: 1rem;
  text-align:justify;
}

/* View More Section */
.w3l-view-more {
  padding: 2rem 0;
}

.btn-style {
  font-size: 1.25rem;
  padding: 0.75rem 1.5rem;
}
