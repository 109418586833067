

@media (max-width: 768px) {
    .navbar-nav {
      text-align: center;
    }
    .navbar-nav .nav-item {
      margin: 0;
    }
    .navbar-toggler {
      display: block;
      margin-left: 35vh;
      margin-top: -10vh;
    }
    .navbar{
        width: 0vh;
        margin-left: -1vh;
    }
    .logo{
        height: 6.5vh !important;
    }
  }
  
  
  