/* Breadcrumb Section Styles */
.w3l-about-breadcrumb {
  position: relative;
  height: 70vh; /* Adjust height as needed */
  overflow: hidden; /* Ensures content doesn't overflow */
}
.A{
  color: white;
}
.breadcrumb-image-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Ensures content doesn't overflow */
}

.breadcrumb-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures image covers the area without distortion */
  transition: transform 13s ease-in-out; /* Smooth zoom effect */
  animation: zoomIn 10s ease-in-out infinite;
}

/* Zoom In Animation */
@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.breadcrumb-text {
  position: absolute;
  top: 50%;
  left: 5%; /* Slight margin from left edge */
  transform: translateY(-50%);
  padding: 20px 30px; /* Generous padding for readability */
  color: #fff !important; /* White text color for better contrast */
 
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.5); /* Soft shadow for depth */
  max-width: 400px; /* Restrict maximum width of the text block */
  backdrop-filter: blur(10px); /* Blur effect for a modern touch */
  animation: fadeInUp 1s ease-out; /* Fade in effect */
}

/* Text styling for better readability */
.breadcrumb-text h1 {
  margin: 0;
  font-size: 3rem; /* Adjust font size for heading */
  font-weight: 700; /* Bold text */
  line-height: 1.2;
  letter-spacing: 1px; /* Slight letter spacing */
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.6); /* Subtle shadow for readability */
}

.breadcrumb-text p {
  margin-top: 10px;
  font-size: 1rem; /* Adjust font size for paragraph */
  line-height: 1.5; /* Improved readability */
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.3); /* Subtle shadow for readability */
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 767px) {
  .w3l-about-breadcrumb {
    height: 40vh; /* Adjust height as needed */
  }
 
  
  .breadcrumb-image-container {
    height: 50vh;
  }
  
  .breadcrumb-image {
    height: 40vh;
  }
  
  .breadcrumb-text {
    padding: 10px 15px;
    font-size: 0.9rem;
    left: 5%; /* Maintain alignment for mobile */
    max-width: 90%; /* Ensure it fits well on mobile */
   
  }
  
  .breadcrumb-text h1 {
    font-size: 1.5rem; /* Adjust font size for mobile */
  }
}




/* Stats Section Styles */
.w3l-aboutblock2 .progress-circles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.circle-container {
  text-align: center;
  margin-bottom: 2rem;
}

.circle {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: #eee;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle::before {
  content: attr(data-text);
  font-size: 36px;
  color: #30B455;
  position: absolute;
}

.circle::after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid #30B455;
  border-top: 5px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.circle h4 {
  margin-top: 1rem;
}

/* Add responsive styles if needed */
@media (max-width: 767px) {
  .w3l-aboutblock2 .progress-circles {
    flex-direction: column;
    align-items: center;
  }

  .w3l-about-breadcrumb {
    position: relative;
    height: 40vh; /* Adjust height as needed */
    overflow: hidden; /* Ensures content doesn't overflow */
  }
  
  .breadcrumb-image-container {
    position: relative;
    width: 100%;
    height: 50vh;
  }
  
  .breadcrumb-image {
    width: 100%;
    height: 40vh;
    object-fit: cover; /* Ensures image covers the area without distortion */
  }
  
  .breadcrumb-text {
    position: absolute;
    top: 40%;
    left: 0;
    transform: translateY(-50%);
    padding: 8px;
    color: rgb(0, 0, 0); /* Adjust text color as needed */
    background: rgba(255, 255, 255, 0.5); /* Optional background for better readability */
    text-align: left; /* Align text to the left */
    border-radius: 15px; /* Rounded corners for the background on mobile */
  }
  
  .breadcrumb-text h1, .breadcrumb-text p {
    margin: 0;
    font-size: 1.1rem;
  }

  
}
