.pricingPlans {
    padding: 80px 0;
    background: linear-gradient(to right, #f0f4f8, #e0e6ef);
    color: #333;
}

.breadcrumb-image-container {
    height: 70vh;
}
.P{
    color: white;
}
.breadcrumb-text{
    color:white;
}
.container {
    width: 90%;
    max-width: 1200px;
    margin: auto;
    padding: 0 20px;
}

.planSection {
    margin-bottom: 60px;
}

.planSection h2 {
    text-align: center;
    color: #0056b3;
    margin-bottom: 40px;
    font-size: 2.8rem;
    font-weight: 700;
    text-shadow: 1px 1px 2px rgba(0,0,0,0.1);
}

.plans {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns */
    gap: 20px; /* Adjust the gap between items */
    justify-content: center;
}

.plan {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    padding: 30px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: fadeInUp 1s ease-out;
}

.plan:hover {
    transform: translateY(-10px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

.plan h3 {
    color: #0056b3;
    margin: 0;
    font-size: 1.8rem;
    font-weight: 600;
}

.price {
    font-size: 28px;
    color: #444;
    margin: 20px 0;
    font-weight: 700;
}

.planDetails {
    text-align: left;
    margin: 20px 0;
}

.planDetails p {
    font-size: 16px;
    color: #666;
    margin: 10px 0;
    padding: 10px;
    border-radius: 10px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.planDetails p span {
    font-weight: 600;
    color: #333;
}

.btn {
    background: linear-gradient(to right, #0056b3, #003d80);
    border: none;
    border-radius: 30px;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    padding: 12px 25px;
    margin-top: 20px;
    transition: background 0.3s ease, transform 0.3s ease;
    font-weight: 700;
    box-shadow: 0 4px 8px rgba(0,0,0,0.2);
}

.btn:hover {
    background: linear-gradient(to right, #003d80, #002d5e);
    transform: scale(1.05);
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .plans {
        grid-template-columns: repeat(3, 1fr); /* 3 columns on medium screens */
    }
}

@media (max-width: 900px) {
    .plans {
        grid-template-columns: repeat(2, 1fr); /* 2 columns on smaller screens */
    }
}

@media (max-width: 600px) {
    .plans {
        grid-template-columns: 1fr; /* 1 column on mobile screens */
    }
}
